<template>
  <div class="content">
    <div class="left">
      <div class="subMenu">
        <div class="title">
          <img :src="titleIconUrl" width="23" />
          <span>数据与图件定制</span>
        </div>
        <div class="group">
          <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="选择数据" prop="dataType">
              <el-select
                size="small"
                v-model="form.dataType"
                placeholder="请选择数据"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文件类型" prop="type">
              <el-radio-group v-model="form.type">
                <el-radio label="nc" value="nc">nc</el-radio>
                <el-radio label="tif" value="tif">tif</el-radio>
                <el-radio label="txt" value="txt">txt</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="数据精度" prop="precision">
              <el-select
                size="small"
                v-model="form.precision"
                placeholder="请选择数据精度"
              >
                <el-option
                  v-for="item in dataTypeStep"
                  :key="item.dictKey"
                  :label="item.dictValue"
                  :value="item.dictKey"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div class="divider">数据区域</div>
            <div style="padding-right: 10px">
              <div class="floor1 floortop">
                <el-form-item label="N" prop="north" label-width="30px">
                  <el-input-number
                    size="mini"
                    v-model="form.north"
                    placeholder="North"
                    :step="step"
                    :controls="isControls"
                  ></el-input-number>
                </el-form-item>
              </div>
              <div class="floor1">
                <el-form-item label="W" prop="west" label-width="30px">
                  <el-input-number
                    size="mini"
                    v-model="form.west"
                    placeholder="West"
                    :step="step"
                    :controls="isControls"
                  ></el-input-number>
                </el-form-item>
                <div>
                  <img
                    class="wheel"
                    src="../../assets/drawing/steeringWheel.png"
                    width="100"
                    height="100"
                  />
                </div>
                <el-form-item label="E" prop="east" label-width="30px">
                  <el-input-number
                    size="mini"
                    v-model="form.east"
                    placeholder="East"
                    :step="step"
                    :controls="isControls"
                  ></el-input-number>
                </el-form-item>
              </div>
              <div class="floor1">
                <el-form-item label="S" prop="south" label-width="30px">
                  <el-input-number
                    size="mini"
                    v-model="form.south"
                    placeholder="South"
                    :step="step"
                    :controls="isControls"
                  ></el-input-number>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <div class="tips">经度范围：-180到180；纬度范围：-90到90</div>
          <el-divider></el-divider>
          <div class="foot_item">
            <el-button type="primary" size="small" @click="drawRect"
              >确定</el-button
            >
            <el-button type="primary" size="small" @click="down"
              >下载</el-button
            >
            <el-button size="small" @click="reset">重置</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="rect" :class="{ active: isActive }">
        <el-tooltip effect="dark" content="创建区域" placement="right">
          <div class="item" @click="startDraw">
            <i class="el-icon-edit-outline"></i>
          </div>
        </el-tooltip>
      </div>
      <div id="map" style="width: 100%; height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapCustomization",
  data() {
    return {
      step: 0.1,
      isControls: false,
      menuList: [],
      typeName: "",
      titleIconUrl: require("../../assets/basedata/icon_basedata.png"),
      form: {
        dataType: "Deepth",
        type: "nc",
        precision: "",
        north: undefined,
        west: undefined,
        east: undefined,
        south: undefined,
      },
      options: [
        {
          label: "水深",
          value: "Deepth",
        },
        {
          label: "海浪",
          value: "seaWave",
        },
      ],
      rules: {
        dataType: [
          { required: true, message: "请选择数据类型", trigger: "change" },
        ],
        precision: [
          { required: true, message: "请选择数据精度", trigger: "change" },
        ],
        north: [
          {
            required: true,
            message: "请输入North",
            trigger: ["blur", "change"],
          },
        ],
        west: [
          {
            required: true,
            message: "请输入west",
            trigger: ["blur", "change"],
          },
        ],
        east: [
          {
            required: true,
            message: "请输入east",
            trigger: ["blur", "change"],
          },
        ],
        south: [
          {
            required: true,
            message: "请输入south",
            trigger: ["blur", "change"],
          },
        ],
      },
      points: [],
      rect: {},
      isActive: false,
      // 绘制控件 start
      drawControl: null, // L.Control.Draw 控件对象
      drawObj: null, // 绘制对象
      drawObj2: null, // 绘制对象
      drawLayerGrounp: null, // 图形图层组
      dataTypeStep: [],
    };
  },

  mounted() {
    this.getDir();
    this.$map.initMap();
    this.initDrawControl();
  },
  methods: {
    getDir() {
      this.$request("/dictionary/getDictionariesByType", {
        method: "post",
        data: {
          dictType: "data_type_step",
        },
      }).then((res) => {
        this.dataTypeStep = res.data.list;
      });
    },
    initDrawControl() {
      //初始化绘制控件
      this.drawControl = new L.Control.Draw({
        position: "topright", //控件位置 'topleft'(默认), 'topright', 'bottomleft' or 'bottomright'
        draw: false,
      }).addTo(this.$map.map); // 要添加到 L.map 对象中
      L.drawLocal.draw.handlers.rectangle.tooltip.start =
        "鼠标点击并拖拽鼠标绘制区域";
      // 添加绘制完监听事件
      this.$map.map.on(L.Draw.Event.CREATED, this.drawCreatedBack);
    },
    // 启动绘制
    startDraw() {
      this.isActive = true;
      // 先取消
      if (this.drawObj) {
        this.drawObj.disable();
      }
      if (this.drawLayerGrounp) {
        this.drawLayerGrounp.clearLayers();
      }
      if (this.drawObj2) {
        this.removeRect(this.drawObj2);
        this.drawLayerGrounp = null;
      }
      this.drawObj = new L.Draw.Rectangle(
        this.$map.map,
        this.drawControl.options.draw.rectangle
      );
      // 启动
      this.drawObj.enable();
    },
    // 交互绘制回调
    drawCreatedBack(e) {
      this.isActive = false;
      // 绘制的图形图层对象
      let drawLayer = e.layer;
      let list = e.layer._bounds;
      // 给表单赋值
      this.form.north = (list._northEast.lat * 1).toFixed(2);
      this.form.west = (list._southWest.lng * 1).toFixed(2);
      this.form.east = (list._northEast.lng * 1).toFixed(2);
      this.form.south = (list._southWest.lat * 1).toFixed(2);
      // 判断当前没有图层组，需先添加
      if (!this.drawLayerGrounp) {
        //图层组
        this.drawLayerGrounp = new L.FeatureGroup();
        // 添加
        this.$map.map.addLayer(this.drawLayerGrounp);
      }
      // 添加到图层组
      this.drawLayerGrounp.addLayer(drawLayer);
    },
    // 销毁绘制控件
    destoryDrawCtr() {
      // L.Control.Draw 控件对象
      this.drawControl = null;
      // 绘制对象
      this.drawObj = null;
      // 删除全部绘制的图层
      if (this.drawLayerGrounp) {
        this.removeRect(this.drawLayerGrounp);
      }
      // 取消绘制完监听事件，避免在真正开发中，其它地方也监听了 CREATED 事件
      this.$map.map.off(L.Draw.Event.CREATED, this.drawCreatedBack);
    },
    drawRect() {
      // 定义矩形的地理边界
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let bounds = [
            [this.form.north * 1, this.form.west * 1],
            [this.form.south * 1, this.form.east * 1],
          ];
          if (this.drawObj) {
            this.drawObj.disable();
          }
          if (this.drawLayerGrounp) {
            this.drawLayerGrounp.clearLayers();
          }
          if (this.drawObj2) {
            this.removeRect(this.drawObj2);
            this.drawLayerGrounp = null;
          }
          this.drawObj2 = L.rectangle(bounds, {
            //绘制矩形
            color: "#3388ff",
            weight: 4,
            opacity: 0.5,
          }).addTo(this.$map.map);
        }
      });
    },
    reset() {
      this.drawObj2 && this.removeRect(this.drawObj2);
      this.drawObj && this.drawObj.disable();
      this.drawLayerGrounp && this.drawLayerGrounp.clearLayers();
      this.$refs["form"].resetFields();
    },
    // remove rect
    removeRect(layer) {
      layer && this.$map.map.removeLayer(layer);
    },
    down() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let url = `https://tiles.iocean.cn:44380/download${this.form.dataType}`;
          this.$request(url, {
            method: "post",
            data: {
              type: this.form.type,
              resolution: this.form.precision,
              lon_start: this.form.west,
              lat_start: this.form.south,
              lon_end: this.form.east,
              lat_end: this.form.north,
            },
            holeUrl: true,
          })
            .then((res) => {
            })
            .catch((err) => {
              try {
                let url = err.fileout
                const a = document.createElement('a');
                a.href = url;
                a.download = '121';
                a.click();
              } catch (error) {
                
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0px 0px 30px 0;

  // margin: 0 auto;
  > .left {
    width: 350px;
    margin-right: 20px;

    .subMenu {
      .title {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 25px;
        background: url("../../assets/basedata/left_title.png") no-repeat;
        background-size: 100% 70px;

        > img {
          margin-bottom: -2px;
          margin-right: 15px;
        }

        > span {
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }
      }

      > .group {
        background-color: #fff;
        padding: 10px;

        .divider {
          width: 100%;
          height: 38px;
          line-height: 38px;
          color: #1d78cd;
          font-size: 14px;
          font-weight: bold;
          background-color: #f5f6fa;
          padding: 0 15px;
          border-radius: 5px;
          margin-bottom: 10px;
        }

        .foot_item {
          text-align: center;
        }
      }
    }

    .floor1 {
      display: flex;
      align-items: center;
      justify-content: center;

      .el-form-item {
        margin-bottom: 10px;
      }

      .wheel {
        margin: 0 12px 0 10px;
      }

      /deep/ .el-form-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .el-form-item__content {
          margin-left: 0 !important;
          line-height: inherit;

          .el-form-item__error {
            width: 120px;
          }
        }

        .el-form-item__label {
          height: 28px;
          display: flex;
          align-items: center;
          border-radius: 5px 0 0 5px;
          border: 1px solid #bfc9d4;
          background-color: #f2f4f6;
          color: #566071;
          border-right: none;
        }
      }

      /deep/ .el-input-number--mini {
        width: 60px;
        height: 28px;

        .el-input__inner {
          padding-left: 8px;
          padding-right: 8px;
          border-color: #bfc9d4;
          border-radius: 0 5px 5px 0;
        }
      }
    }

    .tips {
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: #666;
    }
  }

  > .right {
    flex: 1;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #dcdfe6;
    position: relative;

    .rect {
      cursor: pointer;
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 999;
      padding: 5px;
      color: #666;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #e1e1e1;

      &:hover {
        background-color: #40aef2;
        border: 1px solid #40aef2;
        color: #fff;
      }
    }

    .active {
      background-color: #40aef2;
      border: 1px solid #40aef2;
      color: #fff;
    }
  }
}
</style>